let autoScrollers = []
let mouseScrollers = []
const autoScrollerDuration = 40000
let autoScrollerStart = null

function initializeAutoScrollers() {
  autoScrollers = []

  document.querySelectorAll('.autoscroll-text-container').forEach(el => {
    const container = el
    const textPath = el.querySelector('textPath')

    textPath.style.opacity = 0
    textPath.setAttribute('startOffset', 0)

    const path = document.querySelector(textPath.getAttribute('href'))
    const pathLength = path.getTotalLength()
    const textLength = textPath.getComputedTextLength()

    textPath.setAttribute('startOffset', pathLength)
    textPath.style.opacity = 1

    autoScrollers.push({
      container,
      textPath,
      pathLength,
      textLength
    })
  })
}

function resetAutoScrollers() {
  autoScrollers.forEach(scroller => {
    scroller.textPath.setAttribute('startOffset', scroller.pathLength)
  })
}

function animateAutoScrollers(timestamp) {
  if (autoScrollerStart === undefined) {
    autoScrollerStart = timestamp
  }

  const elapsed = timestamp - autoScrollerStart

  if (elapsed <= autoScrollerDuration) {
    autoScrollers.forEach(scroller => {
      const timePos = elapsed / autoScrollerDuration
      const finalPos = ((scroller.pathLength * 2) + scroller.textLength) - Math.abs(scroller.textLength - scroller.pathLength)
      const pos = timePos * finalPos

      scroller.textPath.setAttribute('startOffset', scroller.pathLength - pos)
    })
  } else {
    resetAutoScrollers()
    autoScrollerStart = undefined
  }

  requestAnimationFrame(animateAutoScrollers)
}

initializeAutoScrollers()

if (autoScrollers.length) {
  requestAnimationFrame(animateAutoScrollers)
  window.addEventListener('resize', initializeAutoScrollers)
}

function initializeMouseScrollers() {
  window._scrollers = []
  document.querySelectorAll('.scroll-text-container').forEach(el => {
    const container = el
    const textPath = el.querySelector('textPath')
    const path = document.querySelector(textPath.getAttribute('href'))
    const pathLength = path.getTotalLength()
    mouseScrollers.push({
      container,
      textPath,
      pathLength
    })
  })
}

function listenScrollers() {
  window.addEventListener('resize', initializeMouseScrollers)
  window.addEventListener('scroll', () => {
    if (mouseScrollers.length) {
      requestAnimationFrame(() => {
        mouseScrollers.forEach(scroller => {
          const rect = scroller.container.getBoundingClientRect()
          const scrollPercent = rect.y / window.innerHeight
          scroller.textPath.setAttribute('startOffset', scrollPercent * 1.2 * scroller.pathLength)
        })
      })
    }
  })
}

initializeMouseScrollers()
listenScrollers()


/* MADLIBS  */ 
$(function() {

  $(document).on('keyup', '.orig', function() {

    var $this = $(this),
      id = $this.data('id'),
      val = $this.val();

    $('input[data-id=' + id + '].orig').val(val).trigger('change');
  });

})